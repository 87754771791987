import { Alert, AlertDescription, chakra } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'next-i18next'

const DataFetchAlert = ({ className }: { className?: string }) => {
  const { t } = useTranslation('common');
  return (
    <Alert status="warning" width="fit-content" className={ className }>
      <AlertDescription>
        { t('common.dataFetchError') }
      </AlertDescription>
    </Alert>
  );
};

export default chakra(DataFetchAlert);
