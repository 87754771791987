import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import type { NextPageWithLayout } from 'nextjs/types';

import PageNextJs from 'nextjs/PageNextJs';

import Home from 'ui/pages/Home';
import LayoutHome from 'ui/shared/layout/LayoutHome';

const Page: NextPageWithLayout = () => {
  return (
    <PageNextJs pathname="/">
      <Home/>
    </PageNextJs>
  );
};

Page.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <LayoutHome>
      { page }
    </LayoutHome>
  );
};

export default Page;

export const getServerSideProps = async(ctx: { locale: string }) => {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale || 'en', [ 'common' ])),
    },
  };
};
